import { VehicleSegment } from '@api/models/types';
import { isWhitelistedCalculationParamsPreset } from '@common/login/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import { getVehiclesSegments } from '@data/selectors';
import {
    CalculationParams,
    CalculationParamsPreset,
    defaultCalculationParams,
    defaultCalculationParamsPreset,
    defaultOverspeedLimit,
    savedCalculationParams,
    savedCalculationParamsPreset,
    savedOverspeedLimit,
    setCurrentCalculationParams,
} from '@features/settings/reducer';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DataStatePart, LoginStatePart, PermissionsStatePart, SettingStatePart } from 'src/setup/types';

import CalculationParamsDialogBody from './CalculationParamsDialogBody';
import CalculationParamsDialogFooter from './CalculationParamsDialogFooter';

const CalculationParamsDialog = ({
    allVehiclesSegments,
    toggleDialog,
    savedCalculationParams,
    savedPreset,
    savedOverspeedLimit,
    saveCalculationParams,
    showCalculationParamsPreset,
    shouldShowHarshKPIs,
    shouldShowExcessiveKPIs,
}: {
    allVehiclesSegments: Set<VehicleSegment>;
    toggleDialog: (visible: boolean) => void;
    savedCalculationParams: CalculationParams;
    savedPreset: CalculationParamsPreset;
    savedOverspeedLimit: number;
    showCalculationParamsPreset: boolean;
    saveCalculationParams: (
        previousSavedCalcParams: CalculationParams,
        updatedCalcParams: CalculationParams,
        previousSavedPreset: CalculationParamsPreset,
        updatedPreset: CalculationParamsPreset,
        previousSavedOverspeedLimit: number,
        updatedOverspeedLimit: number
    ) => void;
    shouldShowHarshKPIs: boolean;
    shouldShowExcessiveKPIs: boolean;
}): JSX.Element => {
    const [calculationParams, setCalculationParams] = useState(savedCalculationParams);
    const [preset, setPreset] = useState(savedPreset);
    const [overspeedLimit, setOverspeedLimit] = useState(savedOverspeedLimit);

    const resetToDefault = () => {
        setCalculationParams(defaultCalculationParams);
        setPreset(CalculationParamsPreset.none);
        setOverspeedLimit(defaultOverspeedLimit);
    };

    const cancelChanges = () => {
        toggleDialog(false);
    };

    const applyButtonOnClick = () => {
        isEqual(calculationParams, savedCalculationParams) && overspeedLimit === savedOverspeedLimit
            ? toggleDialog(false)
            : saveCalculationParams(
                  savedCalculationParams,
                  calculationParams,
                  savedPreset,
                  preset,
                  savedOverspeedLimit,
                  overspeedLimit
              );
    };

    const computeTotalWeight = useMemo(
        () =>
            Object.keys(calculationParams).reduce(
                (acc, calcParamName) => acc + calculationParams[calcParamName as keyof CalculationParams].weight,
                0
            ),
        [calculationParams]
    );

    const isResetButtonDisabled = useMemo(
        () =>
            isEqual(calculationParams, defaultCalculationParams) &&
            preset === defaultCalculationParamsPreset &&
            overspeedLimit === defaultOverspeedLimit,
        [calculationParams, overspeedLimit, preset]
    );

    const existsCityOrInterCityBus =
        allVehiclesSegments.has(VehicleSegment.CITY) || allVehiclesSegments.has(VehicleSegment.INTER_CITY);

    return (
        <>
            <Dialog
                show={true}
                onHide={cancelChanges}
                title={<FormattedMessage id="administration.rating.parameterAdaptation" />}
                footer={
                    <CalculationParamsDialogFooter
                        applyButtonOnClick={applyButtonOnClick}
                        applyButtonDisabled={computeTotalWeight !== 100}
                        cancelButtonOnClick={cancelChanges}
                        resetButtonDisabled={isResetButtonDisabled}
                        resetButtonOnClick={resetToDefault}
                    />
                }
                body={
                    <CalculationParamsDialogBody
                        calculationParams={calculationParams}
                        calculationParamsPreset={preset}
                        overspeedLimit={overspeedLimit}
                        showCalculationParamsPreset={showCalculationParamsPreset}
                        setCalculationParams={setCalculationParams}
                        setCalculationParamsPreset={setPreset}
                        setOverspeedLimit={setOverspeedLimit}
                        shouldShowHarshKPIs={shouldShowHarshKPIs && existsCityOrInterCityBus}
                        shouldShowExcessiveKPIS={shouldShowExcessiveKPIs && existsCityOrInterCityBus}
                    />
                }
                bodyClassName="bg-lighter"
                showCloseButton={true}
            />
        </>
    );
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveCalculationParams: (
        previousSavedCalcParams: CalculationParams,
        updatedCalcParams: CalculationParams,
        previousSavedPreset: CalculationParamsPreset,
        updatedPreset: CalculationParamsPreset,
        previousSavedOverspeedLimit: number,
        updatedOverspeedLimit: number
    ) =>
        dispatch(
            setCurrentCalculationParams({
                previousSavedCalcParams,
                updatedCalcParams,
                previousSavedPreset,
                updatedPreset,
                previousSavedOverspeedLimit,
                updatedOverspeedLimit,
                restored: false,
            })
        ),
});

export const mapStateToProps = (state: SettingStatePart & PermissionsStatePart & LoginStatePart & DataStatePart) => ({
    allVehiclesSegments: getVehiclesSegments(state) as Set<VehicleSegment>,
    savedCalculationParams: savedCalculationParams(state),
    savedPreset: savedCalculationParamsPreset(state),
    savedOverspeedLimit: savedOverspeedLimit(state),
    showCalculationParamsPreset: isWhitelistedCalculationParamsPreset(state),
    shouldShowHarshKPIs: getFeatureToggles(state).showHarshKPIs as boolean,
    shouldShowExcessiveKPIs: getFeatureToggles(state).showExcessiveKPIs as boolean,
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculationParamsDialog);
