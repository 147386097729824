import { getPerformVehicles } from '@common/permissions/selectors';
import { containsElectricVehicleType, getSelectedVehicleIds } from '@data/selectors';
import { hideSidebar, showSidebar, SidebarData } from '@features/ui/reducer';
import { getActiveDateRange, getDateRange } from '@features/ui/selectors';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { State } from 'src/setup/types';
import { DateRange, Id } from 'src/types';

import { VEHICLE_ROUTE } from '../../constants/routes';
import Content from './Content';
import useVehicleContentLoader from './useVehicleContentLoader';

type Props = {
    onShowDetails: (data: SidebarData) => void;
    onHideDetails: () => void;
    vehicles?: Id[];
    dateRange: DateRange;
    performVehicles?: Id[];
    activeDateRange: DateRange;
    containsElectric: boolean;
};

export const Entry = ({
    onShowDetails,
    onHideDetails,
    vehicles = [],
    dateRange,
    performVehicles = [],
    activeDateRange,
    containsElectric,
}: Props) => {
    useEffect(() => {
        if (vehicles.length === 0) {
            onHideDetails();
        }
    }, [onHideDetails, vehicles]);

    const parentsWithChildren = useVehicleContentLoader({
        dateRange: activeDateRange,
        vehicles,
        performVehicles,
        containsElectric,
    });

    return (
        <div>
            <Content
                dateRange={dateRange}
                vehicles={vehicles}
                onItemClick={onShowDetails}
                parentsWithChildren={parentsWithChildren}
            />
        </div>
    );
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    onHideDetails: () => dispatch(hideSidebar()),
    onShowDetails: (data: SidebarData) => dispatch(showSidebar({ data, type: VEHICLE_ROUTE })),
});

export default connect(
    (state: State) => ({
        vehicles: getSelectedVehicleIds(state),
        dateRange: getDateRange(state),
        activeDateRange: getActiveDateRange(state),
        performVehicles: getPerformVehicles(state),
        containsElectric: containsElectricVehicleType(state),
    }),
    mapDispatchToProps
)(Entry);
