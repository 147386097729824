import { isKoreanTenant, isLATAMTenant } from '@common/login/selectors';
import { getLegacyPermissions } from '@common/permissions/selectors';
import OverlayTriggerWithInjectedIntl from '@components/OverlayTriggerWithInjectedIntl';
import { getFlagFromLocalStorage, saveFlagToLocalStorage } from '@features/ui/utils';
import { Dispatch } from '@reduxjs/toolkit';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import Teaser from '@rio-cloud/rio-uikit/Teaser';
import TeaserContainer from '@rio-cloud/rio-uikit/TeaserContainer';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { History } from 'history';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { subModules } from '../../constants/subModules';
import {
    URL_BUYBUTTON_PERFORM,
    URL_BUYBUTTON_PERFORM_KOREA,
    URL_MARKETPLACE_PERFORM,
    URL_MARKETPLACE_PERFORM_KOREA,
} from '../../constants/URLs';
import { State } from '../../setup/types';
import { toggleChangeDialog, toggleHelpDialog } from './actions';
import driverTeaser from './static/driver-teaser.png';
import fleetTeaser from './static/fleet-teaser.png';
import headerImage from './static/header.png';
import PerformanceIcon from './static/performance-icon.svg?react';

const key = 'showWelcomeScreen';
export const shouldShowWelcomePage = () => getFlagFromLocalStorage(key);

const UpSellOverlay = () => (
    <div
        className="text-size-20 position-absolute text-color-dark hover-text-color-highlight"
        style={{
            top: '-150px',
            left: 0,
            width: '100%',
            transition: 'color 200ms ease-in',
        }}
    >
        <OverlayTriggerWithInjectedIntl
            placement="top"
            overlay={
                <Tooltip id="tooltip" className="top">
                    <FormattedMessage id="upSell.callToAction" />
                </Tooltip>
            }
        >
            <div className="text-size-400pct">
                <span className="rioglyph rioglyph-parcel rioglyph rioglyph-arrow-right " />
                <span className="rioglyph rioglyph-arrow-right " />
                <span className="rioglyph rioglyph-shopping-cart " />
            </div>
        </OverlayTriggerWithInjectedIntl>
    </div>
);

export const Welcome = ({
    openRelease,
    openHelp,
    history,
    legacyPermissions,
    isKoreanTenant = false,
    isLATAMTenant = false,
}: {
    openRelease: () => void;
    openHelp: () => void;
    history: History;
    legacyPermissions: Record<string, unknown>;
    isKoreanTenant?: boolean;
    isLATAMTenant?: boolean;
}) => {
    const [showWelcomeScreen, toggleWelcomeScreen] = useState<boolean>(shouldShowWelcomePage());

    useEffect(() => {
        saveFlagToLocalStorage(key, showWelcomeScreen);
    }, [showWelcomeScreen]);

    const marketPlaceURL = isKoreanTenant ? URL_MARKETPLACE_PERFORM_KOREA : URL_MARKETPLACE_PERFORM;
    const buyButtonURL = isKoreanTenant ? URL_BUYBUTTON_PERFORM_KOREA : URL_BUYBUTTON_PERFORM;

    const teaserContent = subModules
        .filter(module => ['vehicle', isLATAMTenant ? '' : 'driver'].includes(module.id))
        .map(module => ({
            ...module,
            ...(module.id === 'vehicle'
                ? {
                      teaserMessage: 'welcomepage.vehicleTeaser',
                      teaserImage: fleetTeaser,
                      glyphs: ['rioglyph-van', 'rioglyph-truck', 'rioglyph-bus'],
                  }
                : {
                      teaserMessage: 'welcomepage.driverTeaser',
                      teaserImage: driverTeaser,
                      glyphs: ['rioglyph-driver'],
                  }),
        }));

    return (
        <div className="container-fluid fluid-default" data-test="WelcomePage">
            <div className="padding-bottom-15">
                <div
                    style={{ background: `url(${headerImage})`, height: 300, backgroundSize: 'cover' }}
                    className="position-relative bg-white border-width-1 border-color-light border-style-solid rounded overflow-hidden"
                >
                    <div
                        className="bg-highlight text-color-white padding-20 flex-column position-absolute"
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                    >
                        <div className="position-relative">
                            <h1 className="margin-0 padding-0 text-size-14-sd text-size-h1-md">
                                <FormattedMessage id="moduleName" />
                                <span className="padding-left-15">
                                    <PerformanceIcon />
                                </span>
                            </h1>
                        </div>
                        <small className="text-size-20">
                            <FormattedMessage id="welcomepage.teaser" />
                        </small>
                    </div>
                    <div className="position-absolute right-0 bottom-0 display-flex bg-white border-width-1 border-bottom-0 border-right-0 border-color-light border-style-solid padding-10">
                        <Checkbox onChange={() => toggleWelcomeScreen(!showWelcomeScreen)} checked={showWelcomeScreen}>
                            <FormattedMessage id="welcomepage.showWelcomePage" />
                        </Checkbox>
                    </div>
                </div>
            </div>

            {!isLATAMTenant && (
                <div className="panel panel-default panel-body margin-bottom-0">
                    <iframe title="marketplace" className="buyButton large responsive" src={buyButtonURL}></iframe>
                </div>
            )}

            <div className="padding-bottom-15 padding-top-15">
                <TeaserContainer>
                    {teaserContent.map(module => {
                        const allowTransition = _.get(legacyPermissions, ['submodules', module.legacyPermission]);
                        return (
                            <div
                                key={module.id}
                                data-test="teaser-container-button"
                                onClick={() =>
                                    allowTransition
                                        ? history.push(module.route)
                                        : (window.location.href = marketPlaceURL)
                                }
                                className={'cursor-pointer'}
                            >
                                <Teaser
                                    headline={
                                        <div>
                                            <div
                                                style={{ marginTop: -20 }}
                                                className="text-size-20 text-bold margin-bottom-20 display-flex justify-content-between align-items-center"
                                            >
                                                {allowTransition ? (
                                                    <Link to={module.route}>
                                                        <FormattedMessage id={module.translationKey} />
                                                    </Link>
                                                ) : (
                                                    <FormattedMessage id={module.translationKey} />
                                                )}
                                                <div className="text-size-14 text-color-darker">
                                                    <div className="text-size-200pct ">
                                                        {module.glyphs.map(glyph => (
                                                            <span key={glyph} className={`rioglyph ${glyph}`} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-size-14 text-normal text-left">
                                                <FormattedMessage id={module.teaserMessage} />
                                            </p>
                                        </div>
                                    }
                                    segmentation="100"
                                    content={allowTransition ? null : <UpSellOverlay />}
                                    image={{
                                        className: allowTransition ? '' : 'opacity-50',
                                        src: module.teaserImage,
                                        aspectRatio: '3:1',
                                    }}
                                />
                            </div>
                        );
                    })}
                </TeaserContainer>
            </div>

            <div className="text-center padding-bottom-15 padding-left-20pct-sm padding-right-20pct-sm">
                <div className="text-size-h3 text-bold text-uppercase">
                    <FormattedMessage id="welcomepage.subtitle" />
                </div>
                <div className="text-size-18">
                    <FormattedMessage id="welcomepage.description" />
                </div>
            </div>

            <div className="text-center margin-bottom-20 padding-top-15 padding-left-20pct-sm padding-right-20pct-sm equal-height justify-content-center">
                <button className="btn btn-lg btn-primary" onClick={openHelp}>
                    <FormattedMessage id="welcomepage.help" />
                </button>

                <div className="bg-light padding-2 margin-left-10 margin-right-10 margin-top-10 margin-bottom-10 hidden-xs hidden-ls" />

                <button className="btn btn-lg btn-primary" onClick={openRelease}>
                    <FormattedMessage id="welcomepage.release" />
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openRelease: () => dispatch(toggleChangeDialog()),
    openHelp: () => dispatch(toggleHelpDialog()),
});

export default connect(
    (state: State) => ({
        legacyPermissions: getLegacyPermissions(state),
        isKoreanTenant: isKoreanTenant(state),
        isLATAMTenant: isLATAMTenant(state),
    }),
    mapDispatchToProps
)(Welcome);
