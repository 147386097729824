import get from 'lodash/fp/get';
// This is the runtime configuration being used for local development
// where authorization is usually mocked

const location = (get('location.origin', window) || '').replace('8080', '3001');
export const localConfig = {
    backend: {
        PERFORMANCE_PLATFORM_INFO: `${location}/platforminfo`,
        USERADMIN_SERVICE: `${location}/user-management`,
        ROUTE_HISTORY: `${location}/api/historic-positions/assets`,
        PERFORMANCE_MERGER: `${location}/api/queryservice`,
        QUERY_SERVICE: `${location}/api/queryservice`,
        CONNECTED_CODRIVER_SERVICE: `${location}`,
        FLEETADMIN_SERVICE: `${location}/api/fleetadmin`,
        EXCEL_EXPORT_SERVICE: location,
        OPCON: `${location}/opcon`,
        HERE_TOKEN_HANDLING: `${location}/map/configurations`,
        MENU_URL: `${location}/menu/rio`,
        FLEET_ASSISTANT_URL: 'https://fleetassistant-staging.rio.cloud/iframe-test',
        TAGS_SERVICE: `${location}/tags`,
        ASSETS_SERVICE: `${location}/assets`,
        DRIVERS_SERVICE: `${location}/drivers`,
        ACCOUNTS_SERVICE: `${location}`,
        PDF_SERVICE: `http://127.0.0.1:3000`,
        SETTINGS_SERVICE: `${location}/settings`,
        VEHICLE_SPEC: `${location}/vehspec`,
        REPORTS_SERVICE: `${location}/reporting`,
        HERE_GEOCODE_URL: 'https://geocode.search.hereapi.com/v1/geocode',
        HERE_GEOFENCING_URL: 'https://geofencing.hereapi.com/v8/geofence',
        HERE_MAPS_URL: 'https://smap.hereapi.com/v8/maps/index',
        HERE_MAPS_ATTRIBUTES_URL: 'https://smap.hereapi.com/v8/maps/attributes',
    },
    homeRoute: `${window.location.origin}`,
    id: 'env.local',
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: true,
        mockLocale: 'en-GB',
        preventRedirect: true,
    },
    logoutUri: `${window.location.origin}`,
};
