import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showFleetDialog: false,
};

const fleetAssistantSlice = createSlice({
    name: 'fleetAssistant',
    initialState,
    reducers: {
        toggleFleetDialog(state) {
            return {
                ...state,
                showFleetDialog: !state.showFleetDialog,
            };
        },
    },
});

export const { toggleFleetDialog } = fleetAssistantSlice.actions;

export default fleetAssistantSlice.reducer;
