import { decodeAccount } from '@api/decoder/accountDecoder';
import { createSlice } from '@reduxjs/toolkit';

export type LoginState = {
    hasUserSessionEverExpired: boolean;
    userProfile: {
        [key: string]: string | undefined;
    } | null;
    userSessionExpired: boolean;
    countryCode: string | null;
};

export const defaultState: LoginState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
    countryCode: null,
};

const loginSlice = createSlice({
    name: 'login',
    initialState: defaultState,
    reducers: {
        userProfileObtained(state, { payload }) {
            return {
                ...state,
                userProfile: payload,
            };
        },
        userSessionExpired(state) {
            return {
                ...state,
                hasUserSessionEverExpired: true,
                userSessionExpired: true,
            };
        },
        userSessionRenewed(state) {
            return {
                ...state,
                userSessionExpired: false,
            };
        },
        accountFetched(state, { payload }) {
            const account = decodeAccount(payload);
            return {
                ...state,
                countryCode: account.legalAddress.countryCode,
            };
        },
    },
});

export const { userProfileObtained, userSessionExpired, userSessionRenewed, accountFetched } = loginSlice.actions;

export default loginSlice.reducer;
