import IframeResizer from 'iframe-resizer-react';

interface FleetIframeProps {
    fleetSrc: string;
}

const FleetAssistantIframe: React.FC<FleetIframeProps> = ({ fleetSrc }) => {
    return (
        <IframeResizer
            id="iframeFleetAssistant"
            src={fleetSrc}
            className="unstyled width-100pct no-application-header"
            style={{ width: '100%', height: '100%', border: 'none' }}
        />
    );
};

export default FleetAssistantIframe;
