import { isLATAMTenant } from '@common/login/selectors';
import {
    CURRENT_MONTH,
    CURRENT_WEEK,
    CURRENT_YEAR,
    dayChangeDetection,
    generateDateRangeConfig,
    LAST_10_DAYS,
    LAST_14_DAYS,
    LAST_MONTH,
    LAST_WEEK,
    LAST_YEAR,
    TODAY,
    YESTERDAY,
} from '@utils/dateRangePresets';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { PermissionsStatePart } from '../../setup/types';
import { LegacyPermissionsType } from './reducer';

export const getPermissions = (state: PermissionsStatePart): { legacy: LegacyPermissionsType } =>
    _.get(state, 'permissions');

export const getLegacyPermissions = (state: PermissionsStatePart) => _.get(getPermissions(state), 'legacy');

export const getPerformReducedBus = (state: PermissionsStatePart): string[] =>
    _.get(getLegacyPermissions(state), 'performReducedBus', []);

export const getPerformVehicles = (state: PermissionsStatePart): string[] => {
    const performVehicles = _.get(getLegacyPermissions(state), 'perform', []);
    const performReducedBus = getPerformReducedBus(state);
    return _.difference(performVehicles, performReducedBus);
};

export const getAdvanceVehicles = (state: PermissionsStatePart): string[] => {
    const advanceVehicles = _.get(getLegacyPermissions(state), 'advance', []);
    return advanceVehicles;
};

export const hasPerformVehicles = (state: PermissionsStatePart): boolean => getPerformVehicles(state).length > 0;

export const LATAMDateRangePresets = {
    [TODAY]: true,
    [YESTERDAY]: true,
    [LAST_10_DAYS]: true,
    [LAST_14_DAYS]: true,
    [CURRENT_WEEK]: true,
    [CURRENT_MONTH]: true,
    [CURRENT_YEAR]: true,
    [LAST_WEEK]: true,
    [LAST_MONTH]: true,
    [LAST_YEAR]: true,
};

export const getPermissionBasedDateRangeConfig = createSelector(
    [getLegacyPermissions, isLATAMTenant, dayChangeDetection],
    (legacyPermissions, isLatamTenant) =>
        generateDateRangeConfig({
            featureFlags: isLatamTenant ? LATAMDateRangePresets : legacyPermissions.dateRangePresets,
            tenDaysLimit: isLatamTenant ? false : legacyPermissions.tenDaysLimit,
            isTodayAllowed: isLatamTenant ? true : legacyPermissions.isTodayAllowed,
            hasTimePicker: isLatamTenant ? true : legacyPermissions.isTimeSelectionAllowed,
            useEndOfDay: false,
        })
);

export const getFeatureToggles = (state: PermissionsStatePart) =>
    _.get(state, 'permissions.featureToggles', {
        printOnLambda: false,
        persistSettings: true,
        disableDebounce: false,
        showOvertakeInCruise: false,
        showHarshKPIs: false,
        showExcessiveKPIs: false,
        truE_EEF: false,
        newOvertakeModel: false,
        showFleetAssistant: false,
    });
