import { userProfileObtained, userSessionExpired, userSessionRenewed } from '@common/login/actions';
import { UserPublishedInfo } from '@common/login/login';
import { accountFetched } from '@common/login/reducer';
import { storeLegacyPermissions } from '@common/permissions/actions';
import { accessTokenStored, hereTokenStored, idTokenStored } from '@common/tokenHandling/actions';
import { AccessTokenStorage } from '@common/tokenHandling/storage';
import { NoMatch } from '@features/app/NoMatch';
import { setIsMobile } from '@features/ui/reducer';
import { Store } from '@reduxjs/toolkit';
import * as RioUserMenuComponent from '@rio-cloud/rio-user-menu-component';
import _ from 'lodash';
import moment from 'moment';
import { Renderer } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import AppContainer from './AppContainer';
import { env } from './env';
import RootErrorBoundary from './RootErrorBoundary';
import { configureReporting, configureReportingGlobal } from './setup/errorReporting';
import { history } from './setup/store';
import { LangStatePart } from './setup/types';

const { captureException } = configureReporting(window, import.meta.env);

export type OAuthConfigSettings = {
    onSessionError: (error: Error) => unknown;
    onTokenExpired: () => unknown;
    onTokenRenewed: (result: UserPublishedInfo) => unknown;
};

export const trace = env.featureToggles.tracing
    ? // eslint-disable-next-line no-console
      (...args: unknown[]) => console.log(`[src/index]`, ...args)
    : () => {};

export type MainProps = {
    accessTokenStorage: AccessTokenStorage;
    configureFetchHereSettings: (token: string, fetch?: typeof window.fetch) => () => Promise<Response>;
    configureFetchLanguageData: (
        store: Store,
        fetchLanguageData?: (locale?: string) => Promise<unknown>,
        fetchMomentLocaleData?: (locale: string) => void,
        getSupportedLocale?: (state: LangStatePart) => string
    ) => (locale: string) => Promise<Response>;
    configureFetchLegacyPermissions: (token: string, fetch?: typeof window.fetch) => () => Promise<Response>;
    configureFetchAccount: (token: string, account: string, fetch?: typeof window.fetch) => () => Promise<Response>;
    document: Document;
    oauthBehavior: (settings: OAuthConfigSettings) => Promise<void>;
    render: Renderer;
    store: Store;
};

function main({
    accessTokenStorage,
    configureFetchHereSettings,
    configureFetchLanguageData,
    configureFetchLegacyPermissions,
    configureFetchAccount,
    document,
    oauthBehavior,
    render,
    store,
}: MainProps) {
    const root = document.querySelector('#root');
    const fetchLanguageData = configureFetchLanguageData(store);

    document.addEventListener(
        RioUserMenuComponent.EVENT_USER_LANGUAGE_CHANGED,
        ({ detail = {} }: Event & { detail?: { language?: string } }) => {
            if (detail.language) {
                moment.locale(detail.language);
                return fetchLanguageData(detail.language);
            }
            return;
        }
    );

    // If we render on mobile we set emit isMobile
    if (document && document.documentElement && !document.documentElement.classList.contains('ua-desktop')) {
        store.dispatch(setIsMobile());
    }

    let renderApp = () => {
        renderApp = () => {};

        // Note that we need to use the base "Router" with a "hash" history
        // because the "HashRouter" doesn't allow handing in a history
        // from the outside. So this is effectively a "HashRouter" despite
        // that not being obvious here
        render(
            <RootErrorBoundary>
                <Provider store={store}>
                    <Router history={history}>
                        <Switch>
                            <Route path="/error" component={NoMatch} />
                            <Route path="/" component={AppContainer} />
                            <Route component={NoMatch} />
                        </Switch>
                    </Router>
                </Provider>
            </RootErrorBoundary>,
            root
        );
    };

    const oauthConfig: OAuthConfigSettings = {
        onSessionError: error => {
            trace('index.onSessionError', error);
            captureException(error);
        },
        onTokenExpired: () => {
            trace('index.onTokenExpired');

            accessTokenStorage.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onTokenRenewed: (result: UserPublishedInfo) => {
            trace('index.onTokenRenewed', result);
            const { accessToken, profile } = result;
            const fetchHereSettings = configureFetchHereSettings(accessToken);
            const fetchLegacyPermissions = configureFetchLegacyPermissions(accessToken);

            const account = _.get(result, 'profile.account');

            if (env.shouldSendMetrics) {
                configureReportingGlobal({ account });
            }

            accessTokenStorage.saveAccessToken(accessToken);
            store.dispatch(accessTokenStored(accessToken));
            store.dispatch(idTokenStored(profile));
            store.dispatch(userProfileObtained(profile));
            store.dispatch(userSessionRenewed());

            fetchHereSettings()
                .then(hereSettings => store.dispatch(hereTokenStored(hereSettings || {})))
                .catch((error: Error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    captureException(error);
                });

            fetchLegacyPermissions()
                .then(legacyPermissions => store.dispatch(storeLegacyPermissions(legacyPermissions)))
                .catch((error: Error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    captureException(error);
                });

            if (account != null) {
                const fetchAccount = configureFetchAccount(accessToken, account);
                fetchAccount()
                    .then(rawAccount => store.dispatch(accountFetched(rawAccount)))
                    .catch((error: Error) => {
                        // eslint-disable-next-line no-console
                        console.error(error);
                        captureException(error);
                    });
            }

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages from the CDN. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            const { enforcedLocale } = env.featureToggles;

            if (enforcedLocale && typeof enforcedLocale === 'string') {
                moment.locale(enforcedLocale);
                trace(`Enforcing locale "${enforcedLocale}" due to feature toggle`);

                fetchLanguageData(enforcedLocale)
                    .then(() => {
                        trace(`Language data fetched for enforced locale "${result.locale}"`);
                        renderApp();
                    })
                    .catch((error: Error) => {
                        // eslint-disable-next-line no-console, max-len
                        console.error(
                            `Language data for enforced locale "${enforcedLocale}" could not be fetched.`,
                            error
                        );
                        captureException(error);
                    });
            } else {
                fetchLanguageData(result.locale)
                    .then(() => {
                        moment.locale(result.locale);
                        trace(`Language data fetched for "${result.locale}"`);
                        renderApp();
                    })
                    .catch((error: Error) => {
                        // eslint-disable-next-line no-console, max-len
                        console.error(`Language data for "${result.locale}" could not be fetched.`, error);
                        captureException(error);
                    });
            }
        },
    };

    oauthBehavior(oauthConfig).catch((error: Error) => {
        trace('auth problem?', error);
    });
}

export default main;
