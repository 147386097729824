import FleetAssistantIframe from '@features/fleetAssistant/FleetAssistantIframe';
import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';

interface FleetDialogProps {
    show: boolean;
    toggleFleetDialog: () => void;
    fleetSrc: string;
}

const dialogTitle = 'fleetAssistant.title';

const getDialogTitle = (intl: WrappedComponentProps['intl'], icon = '') => {
    const titleText = intl.formatMessage({ id: dialogTitle });
    const titleIcon = icon ? <span className={`icon rioglyph ${icon}`} /> : null;

    return (
        <div className="dialog-title">
            <h2 className="margin-0">
                {titleIcon} {titleText}
            </h2>
        </div>
    );
};

const openLinkInNewWindow = (url: string): void => {
    window.open(url, '_blank');
};

const FleetDialog: React.FC<FleetDialogProps & WrappedComponentProps> = ({
    show,
    toggleFleetDialog,
    intl,
    fleetSrc,
}) => {
    const title = getDialogTitle(intl, 'rioglyph-robot');

    const headerBtn = (
        <Button
            bsStyle="muted"
            data-test="fleet-assistant-dialog-open-new"
            iconOnly
            iconName="rioglyph-new-window"
            onClick={() => openLinkInNewWindow(fleetSrc)}
        />
    );

    return (
        <MemoryRouter>
            <Dialog
                show={show}
                className="exampleDialog"
                bodyClassName="padding-0"
                bsSize={Dialog.SIZE_FULL_SCREEN}
                onClose={toggleFleetDialog}
                showCloseButton
                data-test="fleet-assistant-dialog"
            >
                <Dialog.Title title={title} headerButtons={headerBtn} />

                <Dialog.Body className="padding-0">
                    <FleetAssistantIframe fleetSrc={fleetSrc} />
                </Dialog.Body>
            </Dialog>
        </MemoryRouter>
    );
};

export default connect()(injectIntl(FleetDialog));
