import { createSignal, getSignal } from '@api/index';
import useOpconData from '@data/hooks/useOpconData';
import usePerformData from '@data/hooks/usePerformData';
import { Loadable as LoadableDataStructure, LoadableType } from '@data/loadable';
import ISODateToMomentDate from '@utils/ISODateToMomentDateObject';
import { Variables } from '@utils/useQuery';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

import { DateRange, HydratedEntity, Id, PerformSegmentBy } from '../../../types';
import {
    DRIVER_REQUEST_ATTRIBUTES,
    DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V1,
    DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V2,
    DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V3,
    DRIVER_REQUEST_ATTRIBUTES_V1_RATINGS,
    DRIVER_REQUEST_ATTRIBUTES_V2_RATINGS,
    ELECTRIC_KPIS_ATTRIBUTES,
    EXCESSIVE_IDLING_RATINGS_ATTRIBUTES,
    HARSH_RATINGS_ATTRIBUTES,
} from '../queries';
import { Data } from '../types';

const removeEmptyEntity = (entity: HydratedEntity) => {
    const start = getSignal<Date | undefined>(entity, 'start', undefined);
    return start !== undefined && ISODateToMomentDate(start).isValid();
};

const mergeParentsWithChildren = (parents: HydratedEntity[], children: HydratedEntity[]): Data[] => {
    const childrenAsMap = _.groupBy(children, child => getSignal(child, 'driverIds', []));
    return parents.map(
        (parent): Data => {
            const driverIdsForParent = getSignal<Id[]>(parent, 'driverIds', []);
            const childrenForParent: HydratedEntity[] = childrenAsMap[driverIdsForParent[0]] || [];

            return {
                ...parent,
                level: 1,
                childrenCount: childrenForParent.length,
                children: childrenForParent.filter(removeEmptyEntity).map(child => ({
                    ...child,
                    level: 2,
                })),
            };
        }
    );
};

const transformOpConToDriverMap = (getKey: { (element: HydratedEntity): string }) => (data: HydratedEntity[]) =>
    data.reduce((map, element) => map.set(getKey(element), element.signals), new Map());

const mergeOpConWithAggregationResponses = (getKey: { (element: HydratedEntity): string }) => (
    entities: HydratedEntity[],
    opCon = new Map()
) =>
    entities.filter(removeEmptyEntity).map(entity => {
        const { signals, ...rest } = entity;
        const key = getKey(entity);
        const opConSignals = opCon.get(key) || {};
        return {
            ...rest,
            signals: {
                ...signals,
                operatingConditionResponse: createSignal(opConSignals),
                operationCondition: _.get(opConSignals, 'score'),
            },
        };
    });

const getKeyForParent = (element: HydratedEntity) =>
    JSON.stringify([...element.drivers.map(driver => _.get(driver, 'driverId'))]);

const getKeyForChildren = (element: HydratedEntity) =>
    JSON.stringify([
        ...element.vehicles.map(vehicle => _.get(vehicle, 'vehicleId')),
        ...element.drivers.map(driver => _.get(driver, 'driverId')),
    ]);

const useDriverContentLoader = ({
    dateRange = {
        start: moment()
            .subtract(1, 'week')
            .toDate(),
        end: moment().toDate(),
    },
    selectedDriverIds,
    vehicles,
    shouldShowHarshKPIs,
    shouldUseV2Ratings,
    shouldShowExcessiveKPIs,
    shouldUseV3Ratings,
}: {
    dateRange: DateRange;
    selectedDriverIds: string[];
    vehicles: string[];
    shouldShowHarshKPIs: boolean;
    shouldUseV2Ratings: boolean;
    shouldShowExcessiveKPIs: boolean;
    shouldUseV3Ratings: boolean;
}): LoadableType<Data[]> => {
    let driverRequestAttributes: string[] = [];

    if (shouldUseV3Ratings) {
        driverRequestAttributes = [
            ...DRIVER_REQUEST_ATTRIBUTES,
            ...DRIVER_REQUEST_ATTRIBUTES_V2_RATINGS,
            ...DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V3,
        ];
    } else if (shouldUseV2Ratings) {
        driverRequestAttributes = [
            ...DRIVER_REQUEST_ATTRIBUTES,
            ...DRIVER_REQUEST_ATTRIBUTES_V2_RATINGS,
            ...DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V2,
        ];
    } else {
        driverRequestAttributes = [
            ...DRIVER_REQUEST_ATTRIBUTES,
            ...DRIVER_REQUEST_ATTRIBUTES_V1_RATINGS,
            ...DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V1,
        ];
    }

    if (shouldShowHarshKPIs) {
        driverRequestAttributes.push(...HARSH_RATINGS_ATTRIBUTES);
    }

    if (shouldShowExcessiveKPIs) {
        driverRequestAttributes.push(...EXCESSIVE_IDLING_RATINGS_ATTRIBUTES);
    }

    driverRequestAttributes.push(...ELECTRIC_KPIS_ATTRIBUTES);

    const loadableEntities = usePerformData(driverRequestAttributes, {
        variables: {
            ...dateRange,
            driverIds: selectedDriverIds,
            vehicleIds: vehicles,
            segmentBy: PerformSegmentBy.driver,
        } as Variables,
        shouldMakeRequest: !_.isEmpty(selectedDriverIds),
    }) as LoadableType<HydratedEntity[]>;

    const opCon = useOpconData({
        dateRange,
        vehicleIds: vehicles,
        driverIds: selectedDriverIds,
        groupBy: ['driver-id'],
    }) as LoadableType<HydratedEntity[]>;

    const loadableChildren = usePerformData(driverRequestAttributes, {
        variables: {
            ...dateRange,
            driverIds: selectedDriverIds,
            vehicleIds: vehicles,
            segmentBy: PerformSegmentBy.driver_and_vehicle,
        } as Variables,
        shouldMakeRequest: !_.isEmpty(selectedDriverIds),
    }) as LoadableType<HydratedEntity[]>;

    const opConChildren = useOpconData({
        dateRange,
        vehicleIds: vehicles,
        driverIds: selectedDriverIds,
        groupBy: ['driver-id', 'asset-id'],
    }) as LoadableType<HydratedEntity[]>;

    return useMemo(() => {
        const opConMap = LoadableDataStructure.map(opCon, transformOpConToDriverMap(getKeyForParent));
        const opConChildrenMap = LoadableDataStructure.map(opConChildren, transformOpConToDriverMap(getKeyForChildren));

        const entitiesWithOpcon = LoadableDataStructure.combine(
            mergeOpConWithAggregationResponses(getKeyForParent),
            loadableEntities,
            opConMap
        );
        const childrenWithOpcon = LoadableDataStructure.combine(
            mergeOpConWithAggregationResponses(getKeyForChildren),
            loadableChildren,
            opConChildrenMap
        );

        return LoadableDataStructure.combine(mergeParentsWithChildren, entitiesWithOpcon, childrenWithOpcon);
    }, [loadableChildren, loadableEntities, opCon, opConChildren]);
};

export default useDriverContentLoader;
