import { VehicleFuelType, VehicleSegment } from '@api/index';
import SimpleErrorState from '@components/SimpleErrorState';
import { Loadable, LoadableType } from '@data/loadable';
import { CalculationParamObject, CalculationParams } from '@features/settings/reducer';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import ExpanderList from '@rio-cloud/rio-uikit/ExpanderList';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { TooltipProps } from '@rio-cloud/rio-uikit/Tooltip';
import { Variables } from '@utils/useQuery';
import _ from 'lodash';
import { always } from 'lodash/fp';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Column, RatioColumn } from '../../columns/createColumn';
import usePerformData from '../../data/hooks/usePerformData';
import { HydratedEntity, PerformSegmentBy, RawDriver } from '../../types';
import {
    acceleration,
    accelerationPerform3,
    accelerationPerform3WithOvertake,
    braking,
    coastingPerform3,
    cruiseBevPerform3,
    cruiseBevPerform3v3,
    cruiseBevPerform3WithOvertake,
    cruisePerform3,
    cruisePerform3v3,
    cruisePerform3WithOvertake,
    excessiveIdling,
    fuelCruiseBevV1,
    fuelCruiseV1,
    harshAcceleration,
    harshBraking,
    overspeed,
} from './configuration';
import Discipline from './Discipline';
import DisciplineTitle from './DisciplineTitle';
import {
    EXCESSIVE_IDLING_KPIS_ATTRIBUTES,
    FORESIGHTED_REQUEST_ATTRIBUTES,
    FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V1_WITHOUT_CC,
    FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V2_WITHOUT_CC,
    FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V3,
    FORESIGHTED_REQUEST_ATTRIBUTES_V1,
    FORESIGHTED_REQUEST_ATTRIBUTES_V2,
    HARSH_KPIS_ATTRIBUTES,
} from './queries';
import { EntityType } from './types';

interface DisciplineI {
    id: string;
    title?: Column | RatioColumn;
    entries: { title: React.ReactNode; values: (Column | RatioColumn | undefined)[] }[];
}

interface DisciplinesObjI {
    coasting?: DisciplineI;
    accelerationpedal?: DisciplineI;
    braking?: DisciplineI;
    cruisecontrol?: DisciplineI;
    overspeed?: DisciplineI;
}

export const createDisciplineListItem = ({
    calculationParams,
    discipline,
    entity,
    tooltipPlacement,
}: {
    calculationParams: { [key: string]: CalculationParamObject };
    discipline: DisciplineI;
    entity: HydratedEntity;
    tooltipPlacement?: TooltipProps['placement'];
}) => ({
    id: discipline.id,
    header: discipline.title && (
        <DisciplineTitle
            title={discipline.title}
            entity={entity}
            calculationParams={calculationParams}
            tooltipPlacement={tooltipPlacement}
        />
    ),
    body: (
        <div className="text-size-12 text-color-dark">
            {discipline.entries.map(({ title, values }, index) => (
                <Discipline columns={values} title={title} entity={entity} key={index} />
            ))}
        </div>
    ),
});

const success = (d: LoadableType<EntityType>) => <>{d}</>;
const failure = () => <SimpleErrorState fullWidth />;
const loading = () => <Spinner />;
const unknown = () => null;

export const ForesightedDriving: React.FC<{
    data: LoadableType<HydratedEntity>;
    calculationParams: CalculationParams;
    children?: ({ entity }: { entity: HydratedEntity }) => void;
    disciplines?: DisciplineI[];
    shouldShowOvertakeInCruise?: boolean;
    shouldUseV2Ratings?: boolean;
    shouldShowHarshKPIs?: boolean;
    shouldShowExcessiveKPIs?: boolean;
    shouldUseV3Ratings?: boolean;
}> = ({
    data = Loadable.createNotRequested(),
    calculationParams,
    children,
    disciplines,
    shouldShowOvertakeInCruise = false,
    shouldUseV2Ratings = true,
    shouldShowHarshKPIs = false,
    shouldShowExcessiveKPIs = false,
    shouldUseV3Ratings = false,
}) => {
    const normalizedCalculationParams: { [x: string]: CalculationParamObject } = {};
    Object.keys(calculationParams).forEach(calcParamKey => {
        normalizedCalculationParams[calcParamKey.replace('Rating', '').toLowerCase()] =
            calculationParams[calcParamKey as keyof CalculationParams];
    });
    const containsCityOrInterCityBus = Loadable.cata(
        data,
        d =>
            d?.vehicles?.some(
                vehicle => vehicle.segment === VehicleSegment.INTER_CITY || vehicle.segment === VehicleSegment.CITY
            ),
        always(false),
        always(false),
        always(false)
    );

    const shouldShowBevKPIs = Loadable.cata(
        data,
        d => d?.vehicles?.some(vehicle => vehicle.fuelType === VehicleFuelType.ELECTRIC),
        always(false),
        always(false),
        always(false)
    );

    if (_.isUndefined(disciplines)) {
        const baseDisciplines = () => {
            if (shouldUseV3Ratings) {
                return shouldShowBevKPIs
                    ? [cruiseBevPerform3v3, overspeed]
                    : [coastingPerform3, acceleration, braking, cruisePerform3v3, overspeed];
            }

            if (shouldUseV2Ratings) {
                if (shouldShowOvertakeInCruise) {
                    return shouldShowBevKPIs
                        ? [cruiseBevPerform3WithOvertake, overspeed]
                        : [
                              coastingPerform3,
                              accelerationPerform3WithOvertake,
                              braking,
                              cruisePerform3WithOvertake,
                              overspeed,
                          ];
                }

                return shouldShowBevKPIs
                    ? [cruiseBevPerform3, overspeed]
                    : [coastingPerform3, accelerationPerform3, braking, cruisePerform3, overspeed];
            }

            return shouldShowBevKPIs
                ? [fuelCruiseBevV1, overspeed]
                : [coastingPerform3, acceleration, braking, fuelCruiseV1, overspeed];
        };

        disciplines = baseDisciplines();

        const shouldAddCityBusKPIs = containsCityOrInterCityBus;
        if (shouldShowHarshKPIs && shouldAddCityBusKPIs) {
            disciplines.push(harshAcceleration, harshBraking);
        }
        if (shouldShowExcessiveKPIs && shouldAddCityBusKPIs) {
            disciplines.push(excessiveIdling);
        }
    }

    const disciplinesToDisplay: DisciplinesObjI = Object.fromEntries(
        disciplines.map((item: DisciplineI) => [item.id.toLowerCase(), item])
    );

    const hiddenColumns = Object.keys(normalizedCalculationParams).reduce(
        (acc: string[], columnName) => (normalizedCalculationParams[columnName].enabled ? acc : [...acc, columnName]),
        []
    );
    hiddenColumns.forEach(columnName => delete disciplinesToDisplay[columnName as keyof DisciplinesObjI]);

    const mappedData = Loadable.map(data, entity => {
        if (entity) {
            return (
                <React.Fragment>
                    <div>
                        {children?.({ entity })}
                        <div className="padding-bottom-10 padding-top-20 text-size-14">
                            <FormattedMessage id="foresightedDriving.signalsTitle" />
                        </div>
                    </div>
                    <ExpanderList
                        items={Object.values(disciplinesToDisplay).map((discipline: DisciplineI, index) => {
                            const isLastDiscipline = index === Object.values(disciplinesToDisplay).length - 1;
                            return createDisciplineListItem({
                                calculationParams: normalizedCalculationParams,
                                discipline,
                                entity,
                                tooltipPlacement: isLastDiscipline ? 'top-start' : undefined,
                            });
                        })}
                    />
                </React.Fragment>
            );
        }
        return <EmptyState headline={<FormattedMessage id="noData" />} fullWidth />;
    });

    return Loadable.cata(mappedData, success, failure, loading, unknown);
};

const isMatchingEntityForDriver = (driverId: string | null) => (entity: EntityType) =>
    (entity?.drivers?.length === 0 && driverId === null) ||
    (entity?.drivers?.length === 1 && driverId === (entity.drivers as RawDriver[])?.[0]?.driverId);

export function ForesightedDrivingByVehicle({
    vehicleIds = [],
    driverIds = [],
    start,
    end,
    children,
    calculationParams,
    shouldShowOvertakeInCruise = false,
    shouldUseV2Ratings,
    shouldShowHarshKPIs = false,
    shouldShowExcessiveIdlingKPIs = false,
    shouldUseV3Ratings = false,
}: {
    vehicleIds?: string[];
    driverIds?: (string | null)[];
    start: Date;
    end: Date;
    children?: ({ entity }: { entity: HydratedEntity }) => JSX.Element;
    calculationParams: CalculationParams;
    shouldShowOvertakeInCruise?: boolean;
    shouldUseV2Ratings: boolean;
    shouldShowHarshKPIs: boolean;
    shouldShowExcessiveIdlingKPIs: boolean;
    shouldUseV3Ratings?: boolean;
}): JSX.Element {
    const requestDataForOneDriver = driverIds.length === 1;
    const segmentBy = requestDataForOneDriver ? PerformSegmentBy.driver_and_vehicle : PerformSegmentBy.vehicle;

    // request data for all drivers and select the wanted entry afterward
    let foresightedRequestAttributes: string[] = [];

    if (shouldUseV3Ratings) {
        foresightedRequestAttributes = [
            ...FORESIGHTED_REQUEST_ATTRIBUTES,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V3,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_V2,
        ];
    } else if (shouldUseV2Ratings) {
        foresightedRequestAttributes = [
            ...FORESIGHTED_REQUEST_ATTRIBUTES,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V2_WITHOUT_CC,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_V1,
        ];
    } else {
        foresightedRequestAttributes = [
            ...FORESIGHTED_REQUEST_ATTRIBUTES,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_V1,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V1_WITHOUT_CC,
        ];
    }

    if (shouldShowHarshKPIs) foresightedRequestAttributes.push(...HARSH_KPIS_ATTRIBUTES);

    if (shouldShowExcessiveIdlingKPIs) foresightedRequestAttributes.push(...EXCESSIVE_IDLING_KPIS_ATTRIBUTES);

    const data = usePerformData(foresightedRequestAttributes, {
        variables: { start, end, vehicleIds, segmentBy } as Variables,
        debounced: false,
    }) as LoadableType<EntityType[]>;

    const desiredData = Loadable.map(data, entities =>
        requestDataForOneDriver ? entities.find(isMatchingEntityForDriver(driverIds[0])) : _.head(entities)
    ) as LoadableType<HydratedEntity>;

    return (
        <ForesightedDriving
            data={desiredData}
            calculationParams={calculationParams}
            shouldShowOvertakeInCruise={shouldShowOvertakeInCruise}
            shouldUseV2Ratings={shouldUseV2Ratings}
            shouldShowHarshKPIs={shouldShowHarshKPIs}
            shouldShowExcessiveKPIs={shouldShowExcessiveIdlingKPIs}
            shouldUseV3Ratings={shouldUseV3Ratings}
        >
            {children}
        </ForesightedDriving>
    );
}

const isMatchingEntityForVehicle = (vehicleId: string) => (entity: HydratedEntity) =>
    entity?.vehicles?.length === 1 && vehicleId === entity.vehicles[0].vehicleId;

export function ForesightedDrivingByDriver({
    vehicleIds = [],
    driverIds = [],
    start,
    end,
    allowedVehicles,
    children,
    calculationParams,
    shouldShowOvertakeInCruise = false,
    shouldUseV2Ratings,
    shouldShowHarshKPIs = false,
    shouldShowExcessiveIdlingKPIs = false,
    shouldUseV3Ratings = false,
}: {
    vehicleIds?: string[];
    driverIds: string[];
    allowedVehicles: string[];
    start: Date;
    end: Date;
    children?: ({ entity }: { entity: HydratedEntity }) => JSX.Element;
    calculationParams: CalculationParams;
    shouldShowOvertakeInCruise?: boolean;
    shouldUseV2Ratings: boolean;
    shouldShowHarshKPIs: boolean;
    shouldShowExcessiveIdlingKPIs: boolean;
    shouldUseV3Ratings?: boolean;
}): JSX.Element {
    const requestDataForOneVehicle = vehicleIds.length === 1;
    const segmentBy = requestDataForOneVehicle ? PerformSegmentBy.driver_and_vehicle : PerformSegmentBy.driver;

    // request data for all perform vehicles and select the wanted entry afterward
    let foresightedRequestAttributes: string[] = [];
    if (shouldUseV3Ratings) {
        foresightedRequestAttributes = [
            ...FORESIGHTED_REQUEST_ATTRIBUTES,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V3,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_V2,
        ];
    } else if (shouldUseV2Ratings) {
        foresightedRequestAttributes = [
            ...FORESIGHTED_REQUEST_ATTRIBUTES,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V2_WITHOUT_CC,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_V1,
        ];
    } else {
        foresightedRequestAttributes = [
            ...FORESIGHTED_REQUEST_ATTRIBUTES,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_V1,
            ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V1_WITHOUT_CC,
        ];
    }

    if (shouldShowHarshKPIs) foresightedRequestAttributes.push(...HARSH_KPIS_ATTRIBUTES);

    if (shouldShowExcessiveIdlingKPIs) foresightedRequestAttributes.push(...EXCESSIVE_IDLING_KPIS_ATTRIBUTES);

    const data = usePerformData(foresightedRequestAttributes, {
        variables: { start, end, driverIds, vehicleIds: allowedVehicles, segmentBy } as Variables,
        debounced: false,
    }) as LoadableType<HydratedEntity[]>;

    const desiredData = Loadable.map(data, entities =>
        requestDataForOneVehicle ? entities.find(isMatchingEntityForVehicle(vehicleIds[0])) : _.head(entities)
    ) as LoadableType<HydratedEntity>;

    return (
        <ForesightedDriving
            data={desiredData}
            calculationParams={calculationParams}
            shouldShowOvertakeInCruise={shouldShowOvertakeInCruise}
            shouldUseV2Ratings={shouldUseV2Ratings}
            shouldShowHarshKPIs={shouldShowHarshKPIs}
            shouldShowExcessiveKPIs={shouldShowExcessiveIdlingKPIs}
            shouldUseV3Ratings={shouldUseV3Ratings}
        >
            {children}
        </ForesightedDriving>
    );
}
