import { env } from '../../env';

const { ACCOUNTS_SERVICE } = env.runtimeConfig.backend;

const configureFetchAccount = (token: string, account: string, fetch = window.fetch) => () =>
    fetch(`${ACCOUNTS_SERVICE}/accounts/${account}`, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    }).then(res => {
        if (!res.ok) {
            throw new Error(`Error during "load account" call: ${res.status} - ${res.statusText}`);
        }
        return res.json();
    });

export default configureFetchAccount;
