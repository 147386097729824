import { string, type, TypeOf } from 'io-ts';
import { Account } from 'src/types';

export const AccountApiResponse = type({
    id: string,
    legal_address: type({
        country_code: string,
    }),
});

export type AccountApiResponseType = TypeOf<typeof AccountApiResponse>;

export function decodeAccount(rawAccount: AccountApiResponseType): Account {
    return { id: rawAccount.id, legalAddress: { countryCode: rawAccount.legal_address.country_code } };
}
