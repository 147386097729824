import { VehicleFuelType } from '@api/index';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { TreeTabs } from '../../constants/treeTabs';
import { TreeStatePart } from '../../setup/types';
import { defaultSelectedFuelTypes } from './reducer';

export const getSelectedVehicleIds = (state: TreeStatePart) => _.get(state, 'tree.selectedVehicles', []);
export const getSelectedDriverIds = (state: TreeStatePart) => _.get(state, 'tree.selectedDrivers', []);
export const getSelectedVehicleGroupIds = (state: TreeStatePart) => _.get(state, 'tree.selectedVehicleGroups', []);
export const getSelectedDriverGroupIds = (state: TreeStatePart) => _.get(state, 'tree.selectedDriverGroups', []);

const getSelectedFuelTypesFromState = (state: TreeStatePart) =>
    _.get(state, 'tree.selectedFuelTypes', defaultSelectedFuelTypes);

export const getSelectedFuelTypes = createSelector(
    [getSelectedFuelTypesFromState],
    (selectedFuelTypesFromState: VehicleFuelType[]) => selectedFuelTypesFromState
);
export const getActiveTab = (state: TreeStatePart) => _.get(state, 'tree.tab', TreeTabs.VEHICLES);
export const showEmptyGroups = (state: TreeStatePart) => state.tree.showEmptyGroups;
