export const STATE_OF_CHARGE_REQUEST_ATTRIBUTES = [
    'driver_ids',
    'interval_desc',
    'result_from',
    'result_to',
    'electric_max_state_of_charge',
    'vehicle_ids',
    'excessive_idling_time',
];

export const BATTERY_DETAILS_REQUEST_ATTRIBUTES = [
    'drivetrain_consumption',
    'drivetrain_consumption_per_km',
    'epto_consumption',
    'epto_consumption_per_km',
    'batterylosses_consumption',
    'batterylosses_consumption_per_km',
    'aircompressor_consumption',
    'aircompressor_consumption_per_km',
    'cooling_consumption',
    'cooling_consumption_per_km',
    'hvac_consumption',
    'hvac_consumption_per_km',
    'vpowersupply_consumption',
    'vpowersupply_consumption_per_km',
    'total_power_consumption',
    'total_segment',
    'electric_efficiency',
];
