import { VehicleFuelType } from '@api/models/types';
import { DEFAULT_LOCALE } from '@common/lang/lang';
import { CalculationParams, defaultCalculationParams } from '@features/settings/reducer';
import { Text } from '@react-pdf/renderer';
import { findValueExtractor, sortByProperty, SortDirection } from '@utils/sortyByProperty';
import _ from 'lodash';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { compose } from 'redux';

import { Column } from '../../../columns/createColumn';
import { DateRange, Driver, HydratedEntity, HydratedSummary, Vehicle } from '../../../types';
import { getColumn } from '../columns';
import FontProvider from '../FontProvider';
import { getUseCase, UseCase } from '../useCaseConfig';
import Content from './Content';
import { getSelectField } from './utils';

const filterColumnsFromUseCase = (excluded: string[]) => (useCase: UseCase) => {
    const excludeColumnsByKey = (columns: Column[]) => columns.filter(column => !excluded.includes(column.key));

    return {
        ...useCase,
        columns: excludeColumnsByKey(useCase.columns),
        summaryColumns: excludeColumnsByKey(useCase.summaryColumns),
    };
};

const sortColumns = (sortOrder: string[]) => (useCase: UseCase) => {
    const sortOrderMap: { [key: string]: number } = sortOrder.reduce(
        (result, order, index) => ({ ...result, [order]: index }),
        {}
    );

    if (_.isEmpty(sortOrder)) {
        return useCase;
    }

    return {
        ...useCase,
        columns: useCase.columns.sort((a, b) => sortOrderMap[a.key] - sortOrderMap[b.key]),
        summaryColumns: useCase.summaryColumns.sort((a, b) => sortOrderMap[a.key] - sortOrderMap[b.key]),
    };
};

const findColumnByKey = (columns: Column[], key: string) => columns.find(column => column.key === key);
const fixSortKey = (sortKey: string) => {
    return sortKey === 'totalRating' ? 'totalRatingV3' : sortKey;
};
const removeEmptyEntities = (entities: HydratedEntity[]) => entities.filter(getSelectField('start'));
const sortEntities = ({ sortColumn, sortOrder }: { sortColumn?: Column; sortOrder: SortDirection }) => {
    const { key, dataField } = sortColumn || {};

    return (entities: HydratedEntity[]) => sortByProperty(entities, dataField, sortOrder, findValueExtractor(key));
};
const uniqueVehiclesAndDriversInEntities = (data: HydratedEntity[]) => ({
    vehicles: _.uniqBy(_.flatten(data.map(getSelectField<Vehicle[]>('vehicles'))), 'vehicleId'),
    drivers: _.uniq(_.flatten(data.map(getSelectField<Driver[]>('drivers')))),
});

const useCorrectTotalRating = (isPerform3Enabled: boolean, shouldUseV3Ratings: boolean) => (
    column: Column
): Column | undefined => {
    if (column.key === 'totalRating' && shouldUseV3Ratings) {
        return getColumn('totalRatingV3');
    } else if (column.key === 'totalRating' && isPerform3Enabled) {
        return getColumn('totalRatingV2');
    }
    return column;
};

export type Settings = {
    totalRatingWithCC: boolean;
    columnSettings: { filteredColumnNames: string[]; columnOrder: string[] };
    sortBy: { key: string; order: SortDirection };
    calculationParameters: CalculationParams;
    isPerform3Enabled: boolean;
    shouldUseV3Ratings: boolean;
};

export type TablePDFProps = {
    summary: HydratedSummary[];
    summaryElectric: HydratedSummary[];
    entities: HydratedEntity[];
    languageData: Record<string, string>;
    locale: string;
    dateRange: DateRange;
    useCase: string;
    titleId: string;
    settings: Settings;
    fileName?: string;
};

export default function Pdf({
    entities,
    summary,
    summaryElectric,
    languageData,
    locale,
    dateRange,
    useCase: useCaseKey,
    titleId,
    settings = {
        totalRatingWithCC: false,
        columnSettings: { filteredColumnNames: [], columnOrder: [] },
        sortBy: { key: '', order: SortDirection.ASCENDING },
        calculationParameters: defaultCalculationParams,
        isPerform3Enabled: true,
        shouldUseV3Ratings: false,
    },
}: TablePDFProps) {
    const isPerform3Enabled = settings.isPerform3Enabled;
    const shouldUseV3Ratings = settings.shouldUseV3Ratings;
    const { filteredColumnNames, columnOrder } = settings.columnSettings;
    const sortBy = settings.sortBy;
    const fixedSortKey = fixSortKey(sortBy.key);

    const setupUseCaseFromString = compose<UseCase>(
        sortColumns(columnOrder),
        filterColumnsFromUseCase(filteredColumnNames),
        getUseCase(isPerform3Enabled, shouldUseV3Ratings)
    );

    const useCase = setupUseCaseFromString(useCaseKey, '');
    const useCaseElectric = setupUseCaseFromString(useCaseKey + '-electric');

    const filterColumns = (columns: (Column | undefined)[]): Column[] =>
        columns.filter((c: Column | undefined): c is Column => Boolean(c));

    const columns = {
        dieselVehicles: filterColumns(
            useCase.columns.map(useCorrectTotalRating(isPerform3Enabled, shouldUseV3Ratings))
        ),
        electricVehicles: filterColumns(
            useCaseElectric.columns.map(useCorrectTotalRating(isPerform3Enabled, shouldUseV3Ratings))
        ),
    };

    const summaryColumns = {
        dieselVehicles: filterColumns(
            useCase.summaryColumns.map(useCorrectTotalRating(isPerform3Enabled, shouldUseV3Ratings))
        ),
        electricVehicles: filterColumns(
            useCaseElectric.summaryColumns.map(useCorrectTotalRating(isPerform3Enabled, shouldUseV3Ratings))
        ),
    };

    const sortAndFilteredEntities = compose<HydratedEntity[]>(
        sortEntities({
            sortColumn: findColumnByKey(columns.dieselVehicles, fixedSortKey),
            sortOrder: sortBy.order,
        }),
        removeEmptyEntities
    );

    const sortedAndFilteredEntities = sortAndFilteredEntities(entities);

    const { vehicles, drivers } = uniqueVehiclesAndDriversInEntities(sortedAndFilteredEntities);

    const dieselVehicles: HydratedEntity[] = [];
    const electricVehicles: HydratedEntity[] = [];

    sortedAndFilteredEntities.forEach(item => {
        if (item.vehicles.some(cItem => cItem.fuelType !== VehicleFuelType.ELECTRIC)) {
            dieselVehicles.push(item);
        }
        if (item.vehicles.some(cItem => cItem.fuelType === VehicleFuelType.ELECTRIC)) {
            electricVehicles.push(item);
        }
    });

    const finalEntities: { dieselVehicles: HydratedEntity[]; electricVehicles: HydratedEntity[] } = {
        dieselVehicles,
        electricVehicles,
    };

    const shouldShowFuelType = vehicles.some(vehicle => vehicle.fuelType === VehicleFuelType.ELECTRIC);

    return (
        <FontProvider locale={locale}>
            <IntlProvider
                key={locale}
                locale={locale}
                messages={languageData}
                defaultLocale={DEFAULT_LOCALE}
                textComponent={Text}
            >
                <Content
                    vehicles={vehicles}
                    drivers={drivers}
                    columns={columns}
                    sortKey={fixedSortKey}
                    dateRange={dateRange}
                    data={finalEntities}
                    summaryColumns={summaryColumns}
                    summary={summary}
                    summaryElectric={summaryElectric}
                    useCaseKey={useCaseKey}
                    title={<FormattedMessage id={titleId} />}
                    calculationParameters={settings.calculationParameters}
                    shouldShowFuelType={shouldShowFuelType}
                />
            </IntlProvider>
        </FontProvider>
    );
}
