import { VehicleFuelType, VehicleSegment } from '@api/index';
import { getLanguageData } from '@common/lang/selectors';
import { getAccount } from '@common/login/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import SimpleErrorState from '@components/SimpleErrorState';
import Summary, {
    createError,
    createLoadedWithDieselAndElectricData,
    createLoading,
} from '@components/summary/Summary';
import { Values } from '@components/summary/types';
import { Loadable, LoadableType } from '@data/loadable';
import { entitiesForGraphSelection } from '@data/selectors';
import { enablePerform3, includeCruiseControlInRating, savedCalculationParams } from '@features/settings/reducer';
import { defaultSelectedFuelTypes } from '@features/tree/reducer';
import { getSelectedFuelTypes } from '@features/tree/selectors';
import { isGraphCollapsed, setIsGraphCollapsed } from '@features/ui/reducer';
import configureGetCreateTimeInterval from '@utils/timeInterval';
import { Variables } from '@utils/useQuery';
import _ from 'lodash';
import always from 'lodash/fp/always';
import { connect } from 'react-redux';

import { Column } from '../../columns/createColumn';
import usePerformData from '../../data/hooks/usePerformData';
import {
    DriverAnalysisStatePart,
    LangStatePart,
    LoginStatePart,
    PermissionsStatePart,
    SettingStatePart,
    TreeStatePart,
    UiStatePart,
} from '../../setup/types';
import { DateRange, Id, PerformSegmentBy } from '../../types';
import { setActiveSummaryTab } from './driverAnalysisReducer';
import { getActiveSummaryTab, getUseCase, getUseCaseSettings } from './driverAnalysisSelectors';
import Graph from './driverDataGraph/index';
import DriverFilterSummary from './DriverFilterSummary';
import {
    DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V1_WITHOUT_CC,
    DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V2_WITHOUT_CC,
    DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V3_WITHOUT_CC,
    DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
    ELECTRIC_DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
    EXCESSIVE_IDLING_RATINGS_ATTRIBUTES,
    HARSH_RATINGS_ATTRIBUTES,
} from './queries';
import { Data } from './types';
import { getUseCaseConfigForKey } from './useCaseConfig';

const timeIntervalType = configureGetCreateTimeInterval({
    days: 'day',
    weeks: 'week',
    months: 'month',
    quarters: 'quarter',
    years: 'year',
});

const CITY_INTERCITY_BUS_KPIS = [
    'discipline.harshAcceleration',
    'discipline.harshBraking',
    'discipline.excessiveIdling',
];

const NON_BEV_RATINGS_KPIS = ['discipline.coasting', 'discipline.accelerationPedal', 'discipline.braking'];

type Props = {
    dateRange: DateRange;
    allowedVehicles: Id[];
    driverIds: Id[];
    columns?: Column[];
    parentsWithChildren: LoadableType<Data[]>;
    isGraphCollapsed?: boolean;
    setIsGraphCollapsed?: (b: boolean) => void;
    activeTab: string | null;
    setActiveTab: (tab: string | null) => void;
    account?: string;
    shouldShowHarshKPIs: boolean;
    shouldUseV2Ratings: boolean;
    shouldShowExcessiveKPIs: boolean;
    selectedFuelTypes?: VehicleFuelType[];
    shouldUseV3Ratings?: boolean;
    haveDriversDrivenAnyElectricVehicle?: boolean;
    languageData: Record<string, string>;
};

export const DriverSummary = ({
    dateRange,
    allowedVehicles,
    driverIds,
    columns = [],
    parentsWithChildren,
    isGraphCollapsed,
    setIsGraphCollapsed,
    activeTab,
    setActiveTab,
    account,
    shouldShowHarshKPIs,
    shouldUseV2Ratings,
    shouldShowExcessiveKPIs,
    selectedFuelTypes = defaultSelectedFuelTypes,
    haveDriversDrivenAnyElectricVehicle = false,
    shouldUseV3Ratings = false,
    languageData,
}: Props) => {
    let driverRequestByTimeAttributes: Set<string>;
    if (shouldUseV3Ratings) {
        driverRequestByTimeAttributes = new Set([
            ...DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
            ...DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V3_WITHOUT_CC,
        ]);
    } else if (shouldUseV2Ratings) {
        driverRequestByTimeAttributes = new Set([
            ...DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
            ...DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V2_WITHOUT_CC,
        ]);
    } else {
        driverRequestByTimeAttributes = new Set([
            ...DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
            ...DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_V1_WITHOUT_CC,
        ]);
    }

    if (shouldShowHarshKPIs && !haveDriversDrivenAnyElectricVehicle) {
        HARSH_RATINGS_ATTRIBUTES.forEach(harshRatingAttribute =>
            driverRequestByTimeAttributes.add(harshRatingAttribute)
        );
    }

    if (shouldShowExcessiveKPIs && !haveDriversDrivenAnyElectricVehicle) {
        EXCESSIVE_IDLING_RATINGS_ATTRIBUTES.forEach(excessiveIdlingAttribute =>
            driverRequestByTimeAttributes.add(excessiveIdlingAttribute)
        );
    }

    const loadableDieselEntities = usePerformData(
        [...driverRequestByTimeAttributes],
        {
            selector: entitiesForGraphSelection,
            variables: {
                ...dateRange,
                driverIds,
                vehicleIds: allowedVehicles,
                intervalType: timeIntervalType(dateRange),
                segmentBy: PerformSegmentBy.interval,
            } as Variables,
            shouldMakeRequest: !_.isEmpty(driverIds),
        },
        ['total_segment']
    ) as ReturnType<typeof entitiesForGraphSelection>;

    const loadableElectricEntities = usePerformData(
        [...ELECTRIC_DRIVER_REQUEST_BY_TIME_ATTRIBUTES],
        {
            selector: entitiesForGraphSelection,
            variables: {
                ...dateRange,
                driverIds,
                vehicleIds: allowedVehicles,
                intervalType: timeIntervalType(dateRange),
                segmentBy: PerformSegmentBy.interval,
            } as Variables,
            shouldMakeRequest: !_.isEmpty(driverIds),
        },
        ['total_segment']
    ) as ReturnType<typeof entitiesForGraphSelection>;

    const allEntities = Loadable.combine(
        (dieselData, electricData) => {
            if (electricData != null) {
                return { dieselData, electricData };
            }
            return { dieselData };
        },
        loadableDieselEntities,
        loadableElectricEntities
    );

    const status = Loadable.cata(
        allEntities,
        createLoadedWithDieselAndElectricData,
        createError,
        createLoading,
        createLoading
    );
    const hasErrored = Loadable.cata(allEntities, always(false), always(true), always(false), always(false));

    const containsCityOrInterCityBus = Loadable.cata(
        parentsWithChildren,
        d =>
            d.some(data =>
                data.vehicles.some(
                    vehicle => vehicle.segment === VehicleSegment.CITY || vehicle.segment === VehicleSegment.INTER_CITY
                )
            ),
        always(false),
        always(false),
        always(false)
    );

    const containsOnlyBEV = Loadable.cata(
        parentsWithChildren,
        d => d.every(data => data.vehicles.every(vehicle => vehicle.fuelType === VehicleFuelType.ELECTRIC)),
        always(false),
        always(false),
        always(false)
    );

    let filteredColumns = columns;

    if (!containsCityOrInterCityBus) {
        filteredColumns = filteredColumns.filter(column => !CITY_INTERCITY_BUS_KPIS.includes(column.labelId));
    }

    if (containsOnlyBEV) {
        filteredColumns = filteredColumns.filter(column => !NON_BEV_RATINGS_KPIS.includes(column.labelId));
    }

    return hasErrored ? (
        <SimpleErrorState fullWidth headlineId={'error.default'} messageId={'error.server'} />
    ) : (
        <Summary
            activeTab={activeTab}
            columns={filteredColumns}
            entities={driverIds}
            header={<DriverFilterSummary loadableEntities={parentsWithChildren} dateRange={dateRange} />}
            isExpanded={!isGraphCollapsed}
            onHeaderClick={setIsGraphCollapsed ? () => setIsGraphCollapsed(!isGraphCollapsed) : undefined}
            onTabChange={setActiveTab}
            status={status}
            account={account}
            location="drivingAnalysis"
            shouldShowBothValues={!containsOnlyBEV}
        >
            {({ activeSection }) => {
                return (
                    <Graph
                        dateRange={dateRange}
                        column={activeSection}
                        dieselData={
                            (activeSection.values as {
                                diesel?: Values;
                                electric: { values?: Values; recuperationValues?: Values | undefined };
                            }).diesel
                        }
                        electricData={
                            (activeSection.values as {
                                diesel?: Values;
                                electric: { values: Values; recuperationValues?: Values | undefined };
                            }).electric
                        }
                        languageData={languageData}
                        selectedFuelTypes={selectedFuelTypes}
                    />
                );
            }}
        </Summary>
    );
};

export const mapStateToProps = (
    state: SettingStatePart &
        DriverAnalysisStatePart &
        UiStatePart &
        PermissionsStatePart &
        LoginStatePart &
        TreeStatePart &
        LangStatePart
) => {
    const useCaseSettings = getUseCaseSettings(state);
    const filteredColumnNames = useCaseSettings.filteredColumnNames;
    const useCase = getUseCase(state);
    const calculationParams = savedCalculationParams(state);
    const summaryColumns = _.get(
        getUseCaseConfigForKey(useCase, {
            includeCruiseControlInRating: includeCruiseControlInRating(state),
            shouldUseV2Ratings: enablePerform3(state),
            calculationParams,
            shouldShowHarshKPIs: getFeatureToggles(state).showHarshKPIs as boolean,
            shouldShowExcessiveKPIs: getFeatureToggles(state).showExcessiveKPIs as boolean,
            shouldUseV3Ratings: getFeatureToggles(state).newOvertakeModel as boolean,
        }),
        'summaryColumns',
        []
    );

    const columns = summaryColumns.filter(column => !filteredColumnNames.includes(column.key));
    return {
        isGraphCollapsed: isGraphCollapsed(state),
        activeTab: getActiveSummaryTab(state),
        columns,
        account: getAccount(state) || undefined,
        shouldShowHarshKPIs: getFeatureToggles(state).showHarshKPIs as boolean,
        shouldUseV2Ratings: enablePerform3(state),
        shouldShowExcessiveKPIs: getFeatureToggles(state).showExcessiveKPIs as boolean,
        selectedFuelTypes: getSelectedFuelTypes(state),
        shouldUseV3Ratings: getFeatureToggles(state).newOvertakeModel as boolean,
        languageData: getLanguageData(state),
    };
};

export default connect(mapStateToProps, dispatch => ({
    setIsGraphCollapsed: (isCollapsed: boolean) => dispatch(setIsGraphCollapsed(isCollapsed)),
    setActiveTab: (tab: string | null) => dispatch(setActiveSummaryTab(tab)),
}))(DriverSummary);
