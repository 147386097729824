import { VehicleFuelType } from '@api/models/types';
import { getLanguageData } from '@common/lang/selectors';
import { getAccount } from '@common/login/selectors';
import SimpleErrorState from '@components/SimpleErrorState';
import Summary, {
    createError,
    createLoadedWithDieselAndElectricData,
    createLoading,
} from '@components/summary/Summary';
import { Data, Values } from '@components/summary/types';
import { Loadable, LoadableType } from '@data/loadable';
import { entitiesForGraphSelection, getElectricVehicles, getSelectedVehicleIds } from '@data/selectors';
import { defaultSelectedFuelTypes } from '@features/tree/reducer';
import { getSelectedFuelTypes } from '@features/tree/selectors';
import { isGraphCollapsed, setIsGraphCollapsed } from '@features/ui/reducer';
import configureGetCreateTimeInterval from '@utils/timeInterval';
import { Variables } from '@utils/useQuery';
import { difference, intersection, isEmpty } from 'lodash';
import always from 'lodash/fp/always';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Column } from '../../columns/createColumn';
import usePerformData from '../../data/hooks/usePerformData';
import { State } from '../../setup/types';
import { DateRange, Id, IntervalTypes, PerformSegmentBy } from '../../types';
import {
    DIESEL_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES,
    ELECTRIC_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES,
    VEHICLE_REQUEST_BY_TIME_COMMON_ATTRIBUTES,
} from './queries';
import { setActiveSummaryTab } from './reducer';
import { getActiveSummaryTab } from './selectors';
import Graph from './vehicleDataGraph';

const timeIntervalType = configureGetCreateTimeInterval({
    days: 'day',
    weeks: 'week',
    months: 'month',
    quarters: 'quarter',
    years: 'year',
});

type Props = {
    columns?: Column[];
    dateRange: DateRange;
    vehicles: Id[];
    allElectricVehicles: Id[];
    filterSummary: React.ReactNode;
    isGraphCollapsed?: boolean;
    setIsGraphCollapsed?: (b: boolean) => void;
    activeTab: string | null;
    setActiveTab: (tab: string | null) => void;
    account: string | undefined;
    languageData: Record<string, string>;
    selectedFuelTypes?: VehicleFuelType[];
};

export const VehicleSummary = ({
    columns = [],
    dateRange,
    vehicles = [],
    allElectricVehicles = [],
    filterSummary,
    isGraphCollapsed,
    setIsGraphCollapsed,
    setActiveTab,
    activeTab,
    account,
    languageData,
    selectedFuelTypes = defaultSelectedFuelTypes,
}: Props) => {
    const selectedFuelVehiclesIds = difference(vehicles, allElectricVehicles);
    const selectedElectricVehiclesIds = intersection(vehicles, allElectricVehicles);

    const shouldMakeRequestForDieselData = !isEmpty(selectedFuelVehiclesIds);
    const loadableDieselEntities = usePerformData(
        [...VEHICLE_REQUEST_BY_TIME_COMMON_ATTRIBUTES, ...DIESEL_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES],
        {
            variables: {
                ...dateRange,
                vehicleIds: selectedFuelVehiclesIds,
                segmentBy: PerformSegmentBy.interval,
                intervalType: timeIntervalType(dateRange) as IntervalTypes,
            } as Variables,
            selector: entitiesForGraphSelection,
            shouldMakeRequest: shouldMakeRequestForDieselData,
        },
        ['total_segment']
    ) as LoadableType<Data[]>;

    const shouldMakeRequestForElectricData = !isEmpty(selectedElectricVehiclesIds);
    const loadableElectricEntities = usePerformData(
        [...VEHICLE_REQUEST_BY_TIME_COMMON_ATTRIBUTES, ...ELECTRIC_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES],
        {
            variables: {
                ...dateRange,
                vehicleIds: selectedElectricVehiclesIds,
                segmentBy: PerformSegmentBy.interval,
                intervalType: timeIntervalType(dateRange) as IntervalTypes,
            } as Variables,
            selector: entitiesForGraphSelection,
            shouldMakeRequest: shouldMakeRequestForElectricData,
        },
        ['total_segment']
    ) as LoadableType<Data[]>;

    const allEntities = Loadable.combine(
        (dieselData, electricData) => {
            return { dieselData, electricData };
        },
        shouldMakeRequestForDieselData ? loadableDieselEntities : Loadable.createDone(undefined),
        shouldMakeRequestForElectricData ? loadableElectricEntities : Loadable.createDone(undefined)
    );

    const hasErrored = Loadable.cata(allEntities, always(false), always(true), always(false), always(false));
    const status = Loadable.cata(
        allEntities,
        createLoadedWithDieselAndElectricData,
        createError,
        createLoading,
        createLoading
    );

    const highlightColor =
        selectedFuelTypes.length === 1 && selectedFuelTypes[0] === VehicleFuelType.ELECTRIC ? 'bg-info' : undefined;

    return hasErrored ? (
        <SimpleErrorState fullWidth headlineId={'error.default'} messageId={'error.server'} />
    ) : (
        <Summary
            activeTab={activeTab}
            columns={columns}
            entities={vehicles}
            header={filterSummary}
            isExpanded={!isGraphCollapsed}
            onHeaderClick={setIsGraphCollapsed ? () => setIsGraphCollapsed(!isGraphCollapsed) : undefined}
            onTabChange={setActiveTab}
            status={status}
            account={account}
            location="fleetAnalysis"
            shouldShowBothValues={shouldMakeRequestForDieselData && shouldMakeRequestForElectricData}
            highlightColor={highlightColor}
        >
            {({ activeSection }) => (
                <Graph
                    dateRange={dateRange}
                    column={activeSection}
                    dieselData={
                        (activeSection.values as {
                            diesel?: Values;
                            electric: { values?: Values; recuperationValues?: Values | undefined };
                        }).diesel
                    }
                    electricData={
                        (activeSection.values as {
                            diesel?: Values;
                            electric: { values: Values; recuperationValues?: Values | undefined };
                        }).electric
                    }
                    languageData={languageData}
                    selectedFuelTypes={selectedFuelTypes}
                />
            )}
        </Summary>
    );
};

export default compose(
    connect(
        (state: State) => ({
            isGraphCollapsed: isGraphCollapsed(state),
            vehicles: getSelectedVehicleIds(state),
            allElectricVehicles: getElectricVehicles(state),
            activeTab: getActiveSummaryTab(state),
            account: getAccount(state) || undefined,
            languageData: getLanguageData(state),
            selectedFuelTypes: getSelectedFuelTypes(state),
        }),
        dispatch => ({
            setIsGraphCollapsed: (isCollapsed: boolean) => dispatch(setIsGraphCollapsed(isCollapsed)),
            setActiveTab: (tab: string | null) => dispatch(setActiveSummaryTab(tab)),
        })
    )
)(VehicleSummary);
